// eslint-disable-next-line
import React, { Component } from 'react'
/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core'
import styled from '@emotion/styled';
import { Button } from '../html/Buttons';
import { H1, H3, P } from '../html/Fonts';

const Overlay = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
`
const OverlayContainer = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
`
const ContainerImage = styled.section`
  width: 50%;
  height: 100%;
`
const ContainerExcerpt = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 50%;
  height: 100%;
  padding: 12% 45px 45px 45px;
  overflow-x: scroll;
`
const Constraint = styled.div`
  max-width: 550px;
`
const ContainerTitle = styled.section`
  text-align: center;
`
const ContainerContent = styled.section`
  text-align: justify;
`
const FadeTop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 90px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
`
const FadeBottom = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 90px;
  background: linear-gradient(0deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
`
const ExitButton = styled.div`
  z-index: 11;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  top: 15px;
  right: 15px;
  padding-bottom: 2px;
  width: 45px;
  height: 45px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.5);

  color: black;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: black;
    color: white;
  }
`

export default class ButtonExcerpt extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }

    this.closeOverlay = this.closeOverlay.bind(this)
    this.openOverlay = this.openOverlay.bind(this)
  }
  
  closeOverlay(e) {
    if(this.state.visible) {
      this.setState({visible: false})
    }
  }

  openOverlay() {
    if(!this.state.visible) {
      this.setState({visible: true})
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({visible: nextProps.visible})
  }

  render() {
    return(
      <>
        <Button onClick={this.openOverlay}>{this.props.excerptButtonName}</Button>
        {this.state.visible &&
          <>
            <Global styles={css` body { overflow:hidden; }`}/>
            <Overlay>
              <ExitButton onClick={this.closeOverlay}>
                &times;
              </ExitButton>
              <OverlayContainer fluid>
                <ContainerImage>
                  {this.props.image}
                </ContainerImage>
                <ContainerExcerpt>
                  <FadeTop/>

                  <Constraint>
                    <ContainerTitle>
                      <H1 css={css`margin-bottom: 0;`}>{this.props.title}</H1>
                      <H3>{this.props.subtitle}</H3>
                    </ContainerTitle>
                    <ContainerContent>
                      {this.props.paragraphs.length > 0 && 
                        this.props.paragraphs.map((paragraph, i) => (
                          <P key={i}>{paragraph}</P>
                        ))
                      }
                    </ContainerContent>
                  </Constraint>

                  <FadeBottom/>
                </ContainerExcerpt>
              </OverlayContainer>
            </Overlay>
          </>
        }
      </>
    )
  }
}
import PropTypes from 'prop-types'

/** @jsx jsx*/
import { css, jsx } from '@emotion/core'

// H31 Elements
import { Container, Row, Col } from '../html/Layout';
import ButtonExcerpt from '../buttons/ButtonExcerpt';

const ExcerptButtons = ({ excerpts }) => (
  <Container as={'section'} fluid>
    <Row>
      <Col xs={0} sm={0} md={3}/>
      <Col 
        xs={12} 
        sm={12} 
        md={6}
        css={css`
          display: flex;
          justify-content: space-evenly;
          & > button {
            margin: 0;
          }
        `}
      >
        {excerpts.length > 0 && 
          excerpts.map((excerpt, i) => (
            <ButtonExcerpt
              key={i}
              excerptButtonName={`Excerpt ${i+1}`}
              image={excerpt.image}
              title={excerpt.title}
              subtitle={excerpt.subtitle}
              paragraphs={excerpt.paragraphs}
            />
          ))
        }
      </Col>
      <Col xs={0} sm={0} md={3}/>
    </Row>
  </Container>
)

ExcerptButtons.propTypes = {
  excerpts: PropTypes.array.isRequired
}

export default ExcerptButtons
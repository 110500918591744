import styled from '@emotion/styled'
import { baseStyles, buttonStyles } from './_config'

export const Button = styled.button`
  color: ${buttonStyles.buttonColorPrimary};
  min-height: 45px;
  margin: 0;
  margin-right: 15px;
  padding: 15px 30px;
  background: ${buttonStyles.buttonColorSecondary};
  border: 1px solid ${buttonStyles.buttonColorPrimary};
  font-size: ${baseStyles.baseFontSize * 0.75}rem;
  font-family: ${buttonStyles.buttonFontFamily};
  font-weight: ${buttonStyles.buttonFontWeight};
  font-style: ${buttonStyles.buttonFontStyle};

  transition: 0.2s;
  outline: none;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:hover {
    color: ${baseStyles.colorWhite};
    background: ${baseStyles.colorSecondary};
    border: 1px solid ${baseStyles.colorSecondary};
  }
`

export const Button2 = styled.button`
  color: ${baseStyles.colorWhite};
  min-height: 45px;
  margin: 0;
  padding: 15px 60px;
  background: ${baseStyles.colorPrimary};
  border: 1px solid ${baseStyles.colorPrimary};
  font-size: ${baseStyles.baseFontSize * 0.75}rem;
  font-family: ${buttonStyles.buttonFontFamily};
  font-weight: ${buttonStyles.buttonFontWeight};
  font-style: ${buttonStyles.buttonFontStyle};

  transition: 0.2s;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:hover {
    color: ${baseStyles.colorWhite};
    background: ${baseStyles.colorSecondary};
    border: 1px solid ${baseStyles.colorSecondary};
  }
`

export const ButtonClose = styled.button`
  position: absolute;
  top: -7.5px;
  right: -7.5px;

  color: #FF2357;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  border-radius: 50%;
  border: 1px solid #FF2357;

  line-height: 0;
  font-size: 20px;
  font-family: sans-serif;
  font-weight: ${buttonStyles.buttonFontWeight};
  font-style: ${buttonStyles.buttonFontStyle};

  transition: 0.2s;
  outline: none;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:hover {
    color: #FFFFFF;
    background: #FF2357;
    border: 1px solid #FF2357;
  }
`
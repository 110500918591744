/** @jsx jsx */
import { css, jsx } from "@emotion/core"

// H31 Components
import SectionBlock from "../components/page-elements/SectionBlock"
import { baseStyles } from "../components/html/_config"
import { P } from "../components/html/Fonts"
import Content from "../components/page-elements/Content"
import ExcerptButtons from "../components/page-elements/ExcerptButtons"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonStripePay from "../components/buttons/ButtonStripePay"

// Images
import { ImgBook } from "../components/images/ImgBook"
import { ImgNickClaraBox } from "../components/images/ImgNickClaraBox"
import { ImgBookExcerptOne } from "../components/images/ImgBookExcerptOne"
import { ImgBookExcerptTwo } from "../components/images/ImgBookExcerptTwo"
import { ImgBookExcerptThree } from "../components/images/ImgBookExcerptThree"

// Data
const excerpts = [
  {
    image: <ImgBookExcerptOne />,
    title: "Intervention",
    subtitle: "Excerpt 1",
    paragraphs: [
      "The grandparents needed help!  Nick was having difficulty walking and taking care of himself.  Clara was no longer able to drive.  I believed that outside help would be beneficial for both of them.  As the phone calls were made, feelings of guilt provoked my imagination to run away with me.",
      "The next day, while my grandparents were out, I began making phone calls. I called social services, senior protective services, and a host of home care agencies. I talked to anyone who could give me any information or direction. I had no idea what to do. All I knew was that something had to be done. My grandparents had other family, but they were dealing with their own health issues.",
      `It was difficult to make these phone calls. It was, as if, I was calling "The Authorities" to come and "take over" their lives and "confiscate" their freedom. I even called the Department of Motor Vehicles to check into the possibility of revoking Grandfather's driving privileges. I felt more like an "informant" than a grandson.`,
      `After a while, I began to have visions of government vehicles coming into the driveway. After the agents stormed into the house, they would find us, all sitting together in the family room. Grandmother sitting in a rocking chair, knitting, Grandfather in his easy chair, reading a paper, and our two boys angelically playing together on the floor as my wife and I sat on the loveseat, holding hands. Then, one of the agents would say, "We received a call from this location reporting two octogenarians unable to function productively in the collective!" I would say, "Yes sir, I made that call. It is my sworn duty to report anyone who is incapable of contributing to the social benefit of the community!" The agent, looking happy, would respond, "Well done! Seize these two! And take them away!" Then my boys would ask, "Daddy, why are they taking Grandpa and Grandma away?" I would put my hands on their shoulders and say, "Well boys, Grandpa and Grandma have outlived their usefulness. It's for the good of our society! And our future will be brighter for the decision we made today!" Oh yeah! I was feeling better by the minute.`,
      `A social worker from one of the home care agencies returned my call. She was very helpful in answering my questions and giving me suggestions on what could be done. The only way she could determine the best way to help would be to meet with them personally. She suggested that we schedule an "in-home assessment."`,
      `Sure, let a social worker from some agency into your home, and in a little while, someone is going to a convalescent home! I thought. I had no idea what to do.  This person, at least, sounded as if she was genuinely interested in offering help. I had to trust someone, and it looked as though the Lord had her in mind.`,
      `That was the easy part. Now my grandparents would have to be convinced to meet with her. In the time I have come to know Nick and Clara, one thing was certain. They were not going to meet with anyone who was associated with a convalescent home or allow anyone to change the way they lived—at least not without putting up a fight. We agreed to meet in the afternoon after the following day. Now all I had to do was find the right time to tell my grandparents.`,
    ],
  },
  {
    image: <ImgBookExcerptTwo />,
    title: "Reluctance",
    subtitle: "Excerpt 2",
    paragraphs: [
      `A heart attack left my grandmother confined to bed. The following excerpt describes my first experience in having to change my grandmother, the consequences of my awkward neglect, and the "Eternal Moment".`,
      `I put the straw to her lips, and as she sipped the water, all I could think about was dealing with the uncomfortable task of changing my grandmother's diaper. The way my grandmother looked at me as her body was exposed during the diaper change ordeal, made me anxious.  She finished her water and we talked for a while. I knew that this diaper issue had to be dealt with. But how was I going to broach the subject?`,
      `Finally, I looked at her and said. "So…uh… do you think you will be all right tonight? Or…uh…do you think you need to be changed?"`,
      `Without any hesitation, she answered back, "No, I'll be okay; you get your rest."`,
      `That was all I needed to hear. A quick, "goodnight" and off I went to bed. It turned out to be a very quiet night.`,
      `In the morning, our caregiver arrived and asked how everything had gone last night. I said that everything was fine, that Clara said she was dry and didn't need changing. Judging by the look on her face, she was not pleased with my answer. As she turned to walk up the steps to Clara's room she said sternly, "You were supposed to change her!"`,
      `I followed her to my grandmother's room hoping to see that everything was fine and be vindicated for not changing her. "Good morning Clara," said the caregiver as she entered the room. "Did you sleep all right?" She pulled back the covers from the bed. Just as she suspected, Clara's bed was soaking wet. The back of her gown was drenched with urine up to the back of her neck even the bottom half of her pillow was wet.`,
      `I could not believe how wet she was. My first thought was, where did all this come from? She must have been holding it back for a week! Before I could come up with my second thought, the caregiver looked up and began reprimanding me for not changing her. After a few minutes of  being scolded by her, my attitude towards her went from apologetic remorse to indignant anger. I began to think to myself, Don't take that attitude with me! You work for us, remember! If you have anything to say, you had better watch your tongue! I don't have to take this from you!`,
      `As my aggravation level towards this woman began to build, God gave me what I have come to define as an “Eternal Moment.” This is where, in a split second, you are allowed to see the long-term ramifications of your next choice of words or action. It is during this split second in time where you are given the opportunity to do what you want to do or to do what God would have you to do. As that moment begins to fade, it usually ends with my prayer of, “Forgive me…help me.”`,
      `In that eternal moment, I was reminded that my grandmother's predicament was caused by me and my reluctance to care for her properly. I was also reminded that this caregiver went above and beyond the care that my grandparents received from all of the other caregivers. My prayer was answered. My blood pressure dropped. I received peace. I began to see her not as someone who was trying to belittle, chastise, and order me around. But rather as an advocate fighting on Clara's behalf. I apologized, helped her strip the bed, and launder the bed sheets.`
    ],
  },
  {
    image: <ImgBookExcerptThree />,
    title: "Rescued",
    subtitle: "Excerpt 3",
    paragraphs: [
      `Shortly after Clara's heart attack , Nick was threatened with having the state seize his home and having him put into a convalescent hospital by a home health aid who was 'Looking after his best interest!'`,
      `In the days prior to Grandmother's transfer, we continued to receive complaints about the new caregiver. According to my grandfather, the man was antagonizing, threatening, and telling lies. Grandfather complained to the agency about him. And when it seemed that nothing was being done, Nick terminated the services of the agency. Now, for the first time, I received a call from this caregiver. It was Friday afternoon when I listened to the message. He introduced himself and expressed how sorry he was to hear about Clara. Then he stated his concern for Nick and that he had only done what he thought was best for Nick. But now that Nick had terminated the services, he was required to contact elder protective services.  The man’s voice dripped with disingenuousness.`,
      `I contacted Grandfather to tell him about the message. "That dirty (expletive)! He told me, ‘We're going to put Clara and you into a home and the state is going to take everything you own!’ I told him to get out of my house! That no good (expletive)!" Grandfather was very upset, to say the least! When he finally calmed down, I told him to take it easy and that everything will work out fine, not to worry.`,
      `Now that Grandfather was all right, I began to get all worked up! What in the world was I going to do from 3,000 miles away? I should have looked to Heaven instead of the world for answers, but I was in ‘react mode.’ There is no time to pray! I have to do something now! Right now! So I made as many phone calls as I could, but the people that I needed to speak to had already left for the weekend.`,
      `In the morning, I called Grandfather to see how he was doing. The other caregiver, the ‘angle from God’ answered the phone. "Nick is doing better," she said. "A man came from protective services to check up on a call he received. He was told that it was an emergency! He stayed for a while and asked some questions. He was satisfied with what he saw, so he left."`,
      `I asked her about the agency being terminated and who was taking care of Grandfather?`,
      `"I also work for another agency," she said. "I will continue to work here until Monday. On Monday the other agency will send out someone to do an assessment and transfer service to the new agency. There shouldn't be any problems with Nick's care being interrupted and I will remain as his caregiver."`,
      `"Praise God!" I said to her. "Thank you, Lord!" I then thanked her for all that she had done for us. Then I spoke to Grandfather. He was fine! It was like a 180° change from the last conversation I had had with him! It was like talking to a completely different person! And since she was bringing Nick to the other agency, they allowed some leeway in our working out additional care arrangements with her. I was reminded, again, of the words our Lord spoke:`,
      `"Seek first his kingdom and his righteousness, and all these things will be given to you as well. Therefore do not worry about tomorrow, for tomorrow will worry about itself each day has enough trouble of its own."  - Matthew 6:33-34 (NIV)`
    ],
  },
]

const BookPage = () => (
  <Layout>
    <SEO 
      title="" 
      description="" 
      keywords={[]}
    />
    <SectionBlock
      title="Our Book on Caring for Aging Family"
      subtitle="Lessons of Faith on the Road to End of Life Care"
      img={<ImgBook />}
    >
      <P
        css={css`
          color: ${baseStyles.colorSecondary};
          font-size: ${baseStyles.baseFontSize * 0.75}rem;
        `}
      >
        ISBN: 9780-5781-16259 | 2012
      </P>
      <ButtonStripePay
        sku="sku_F4usYD6fIcGgPf"
        successPage="book"
        cancelPage="book"
        buttonName="eBook - $4.99"
      />
      <ButtonStripePay
        sku="sku_F4utd9KWu1eo2k"
        successPage="book"
        cancelPage="book"
        buttonName="Paperback - $19.99"
        billingAddress
      />
    </SectionBlock>
    <Content>
      <P>Apart from death, one of the most difficult time a family faces is watching the health of a family member deteriorate.  Whether it is caused by illness, injury, or aging, it is a painful process for everyone involved.  What used to be simple tasks in their every day routine, now, has become a daily struggle.</P>
      <ImgNickClaraBox />
      <P>There was a time, not so long ago, when families lived their entire lives in the town they were born.  It made for an ideal "support system;"  a family network looking out for each other when help was needed.  There were no questions about child day care or caring for a home bound relative.  The vast majority of families took care of one another as a basic responsibility of life.  This was the kind of love that families showed to each other.  Love, through their commitment to care for one another.</P>
      <P>Today, more and more families are being separated.  Frequent moves and divorce have taken their toll.  Not only are families being separated by physical location, but the "support system" of looking out for each other has been weakened or totally removed.  When you take into account the single working parent and the vast majority of young families where both parents work, it is no wonder that, in some areas, child day care facilities dot the landscape like fast food franchises.  On the other end of the spectrum, consider the increase in the number of convalescent homes and assisted living facilities for the elderly.  Could this be in preparation of a future trend of families unable or unwilling to care for our aging population?</P>
      <P>I pray not!</P>
    </Content>
    <ExcerptButtons excerpts={excerpts} />
  </Layout>
)

export default BookPage

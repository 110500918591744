/** @jsx jsx*/
import { css, jsx } from '@emotion/core'

// Components
import { Container, Row, Col } from '../html/Layout';
import { baseStyles } from '../html/_config';

const Content = ({ children }) => (
  <Container as='section' fluid css={css`background: ${baseStyles.colorQuinary};`}>
    <Row>
      <Col xs={0} sm={1} md={2}/>
      <Col 
        xs={12} 
        sm={10} 
        md={8}
        css={css`
          .gatsby-image-wrapper {
            margin-bottom: ${baseStyles.baseMargin}px;
          }
        `}
      >
        {children}
      </Col>
      <Col xs={0} sm={1} md={2}/>
    </Row>
  </Container>
)

export default Content
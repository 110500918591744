import React from "react"
/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import { Button } from "../html/Buttons";


// Pick a url address depending on environment
const url = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/' : 'https://honorthyparents.netlify.com/'


export default class ButtonStripePay extends React.Component {
  // Initialise Stripe.js with your publishable key.
  // You can find your key in the Dashboard:
  // https://dashboard.stripe.com/account/apikeys
  componentDidMount() {
    this.stripe = window.Stripe('pk_test_kaFHQwzFWtUE3pATHaPBAmFB00GD2O26jA')
  }

  async redirectToCheckout(event) {
    event.preventDefault()
    const { error } = await this.stripe.redirectToCheckout({
      items: [{ sku: this.props.sku, quantity: 1 }],
      successUrl: `${url}/${this.props.successPage ? this.props.successPage : ''}`,
      cancelUrl: `${url}/${this.props.cancelPage ? this.props.cancelPage : ''}`,
      billingAddressCollection: `${this.props.billingAddress ? 'required' : 'auto'}`
    })

    if (error) {
      console.warn("Error:", error)
    }
  }

  render() {
    return (
      <Button
        onClick={event => this.redirectToCheckout(event)}
        css={css`margin-bottom: 15px;`}
      >
        {this.props.buttonName}
      </Button>
    )
  }
}